/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/slick-carousel@1.7.1/slick/slick.min.js
 * - /npm/jquery.counterup@2.1.0/jquery.counterup.min.js
 * - /npm/magnific-popup@1.1.0/dist/jquery.magnific-popup.min.js
 * - /npm/waypoints@4.0.1/lib/jquery.waypoints.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
